import { render, staticRenderFns } from "./staffRoster.vue?vue&type=template&id=3180bd12&scoped=true&"
import script from "./staffRoster.vue?vue&type=script&lang=js&"
export * from "./staffRoster.vue?vue&type=script&lang=js&"
import style0 from "./staffRoster.vue?vue&type=style&index=0&id=3180bd12&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3180bd12",
  null
  
)

export default component.exports