<template>
  <div class="organ-management-page">
    <el-row>
      <el-col :span="24">
        <el-card shadow="always">
          <el-form :inline="true" :model="form" class="demo-form-inline search searchFrom" size="small">
            <el-form-item label="员工姓名">
              <el-input clearable v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="员工电话">
              <el-input clearable v-model="form.phone"></el-input>
            </el-form-item>
            <el-form-item label="岗位">
              <el-select v-model="form.state" clearable placeholder="请选择岗位">
                <el-option label="全部 " value=""></el-option>
                <el-option v-for="(item, index) in productList" :key="index" :label="item.dictionaryName" :value="item.dictionaryCode"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="机构">
              <el-select v-model="form.institutions" clearable placeholder="请选择机构">
                <el-option label="全部 " value=""></el-option>
                <el-option v-for="item in companyList" :key="item.companyId" :label="item.companyName" :value="item.companyId"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <el-form :inline="true" :model="form" class="demo-form-inline searchFrom" size="small">
            <el-form-item>
              <el-button icon="el-icon-search" type="primary" :disabled="disabledSearch" @click="getAll(1)">查询</el-button>
            </el-form-item>
            <el-form-item>
              <el-button plain icon="el-icon-upload2" type="primary" @click="importSatff">导入</el-button>
            </el-form-item>
            <el-form-item>
              <el-button plain icon="el-icon-setting" type="primary" @click="updateAddNew">修改</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <el-card shadow="always" class="card">
      <div>
        <el-button plain icon="el-icon-plus" type="primary" @click="showAddNew">添加</el-button>
        <el-button plain icon="el-icon-delete" type="danger" @click="handleDelete">删除</el-button>
      </div>
      <div class="table-block">
        <div class="Selectnum">已选中 <span style="color:#5479FF">{{multipleSelection.length}}</span> 条信息</div>
        <el-table
          ref="multipleTable"
          @row-click="handleClickTableRow"
          :data="tableData"
          v-loading="loading"
          :header-cell-style="{'text-align': 'center','background': '#EEF1FC', 'color': '#999'}"
          :cell-style="{'text-align':'center'}"
          height=386
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="员工姓名" prop="employeeName"> </el-table-column>
          <el-table-column label="员工电话" prop="employeeTel"> </el-table-column>
          <el-table-column prop="employeeDutyName" label="岗位"> </el-table-column>
          <el-table-column prop="companyName" label="机构"> </el-table-column>
          <el-table-column prop="state" label="状态">
            <template slot-scope="scope">
               <el-switch
                v-model="scope.row.employeestuts"
                active-color="#5479FF"
                inactive-color="#D5D5D5"
                active-value="1"
                @change="changeStatus(scope.row)"
                inactive-value="0">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="employeeTime" label="入职时间"> </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[8, 16, 24, 32]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background>
        </el-pagination>
      </div>
    </el-card>
    <addNewSatffRoster :info="info" v-if="showAddNewSatffRoster" @closehandle="closehandle"></addNewSatffRoster>
    <importStaffRoster v-if="isShow" @closestaffRoster="closestaffRoster"></importStaffRoster>
  </div>
</template>

<script>
import { getAllInstitutionsInfo, postRequest } from 'src/api/index'
import importStaffRoster from '@/views/basisSet/component/importStaffRoster'
import addNewSatffRoster from '@/views/basisSet/component/addNewSatffRoster'
export default {
  data() {
    return {
      form: {
        name: '',
        phone: '',
        institutions: '',
        state: '',
      },
      tableData: [],
      multipleSelection: [],
      showAddNewSatffRoster: false,
      companyList: [],
      state: '',
      isShow: false,
      productList: [],
      pageNo: 1,
      pageSize: 8,
      loading:true,
      total: null,
      disabledSearch: false
    }
  },
  components: { addNewSatffRoster, importStaffRoster },
  mounted(){
    let data = {}
    getAllInstitutionsInfo(data).then(res=>{
      console.log(res,'getAllInstitutionsInfo')
      this.companyList = res
    }),
    // 岗位查询
    postRequest('/dict/getDictByCategory',{dictionaryCategoryCode: '03'}).then(res=>{
      console.log(res, '医生、护士、管家')
      this.productList = res
    })
    this.getAll()
  },
  methods: {
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    // 点击行选中
    handleClickTableRow(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    // 选中项
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val
    },
    showAddNew() {
      this.showAddNewSatffRoster = true
      this.info = ''
    },
    updateAddNew(){
      console.log(this.multipleSelection)
      // this.showAddNewSatffRoster = true
      if (this.multipleSelection.length > 1) {
        this.$message({
          message: '只能选择一条信息进行修改',
          type: 'warning'
        })
      }
      if (this.multipleSelection.length == 0) {
        this.$message({
          message: '请选择需要修改的信息',
          type: 'warning'
        })
      }
      if (this.multipleSelection.length == 1) {
        this.showAddNewSatffRoster = true
        this.info = this.multipleSelection[0]
      }
    },
    closehandle() {
      this.showAddNewSatffRoster = false
      this.getAll()
    },
    importSatff() {
      this.isShow = true
      this.getAll()
    },
    // handleClick(val) {
    //   console.log(val)
    //   let data = {
    //     employeeId: val.employeeId,
    //   }
    //   if (val.employeestuts == 1) {
    //     data.employeeStuts = '0'
    //   } 
    //   if (val.employeestuts == 0) {
    //     data.employeeStuts = '1'
    //   }
    //   postRequest('/Employee/update/', data).then(res=>{
    //     console.log(res)
    //     this.getAll()
    //   })
    // },
    changeStatus(val){
      console.log(val)
      let data = {
        employeeId: val.employeeId,
        employeeStuts: val.employeestuts
      }
      postRequest('/Employee/update/', data).then(res=>{
        console.log(res)
        this.$message({
          message: '状态修改成功',
          type: 'success'
        })
        // this.getAll()
      })
    },
    handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.pageSize = val
        this.getAll()
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.pageNo = val
        this.getAll()
      },
    // 查询所有员工
    getAll(no){
      this.loading = true
      this.disabledSearch = true
      let data = {
        employeeName: this.form.name ? this.form.name : '',
        companyCode: this.form.institutions ? this.form.institutions : '',
        employeeTel: this.form.phone ? this.form.phone : '',
        employeeDuty: this.form.state ? this.form.state : '',
        pageNo: no ? no : this.pageNo,
        pageSize: this.pageSize
      }
      postRequest('Employee/queryEmployeePage/',data).then(res=>{
        console.log(res, '查询所有')
        this.disabledSearch = false
        this.loading = false
        this.tableData = res.data
        this.total = res.count
        res.data.map(item=>{
          item.createTime = item.createTime.substring(0, 10)
        })
      }).catch(()=>{
        this.loading = false
        this.disabledSearch = false
      })
    },
    closestaffRoster() {
      this.isShow = false
    },
    handleDelete(){
      if (this.multipleSelection.length <= 0) {
        this.$message({
          message: '请选择删除信息',
        })
      } else {
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          postRequest('/Employee/delete/', this.multipleSelection).then(res=>{
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.getAll()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
    },
    search(){
      let data = {
        employeeName: this.form.name ? this.form.name : '',
        companyCode: this.form.state ? this.form.state : ''
      }
      console.log(data)
      postRequest('/Employee/queryEmployee/', data).then(res=>{
        console.log(res, '根据名称查询')
        res.map(item=>{
          if (item.employeestuts == 1) {
            item.state = '启用'
          }
          if (item.employeestuts == 0) {
            item.state = '禁用'
          }
          this.companyList.map(newItem=>{
            if (item.companyCode == newItem.companyCode) {
              item.companyName = newItem.companyName
            }
          })
        })
        this.tableData = res
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.organ-management-page {
  background: transparent;
}
// .search > .el-form-item {
//   margin-right: 30px !important;
// }
.Selectnum{
  padding:6px 30px;
  border:1px solid #BFCBF4;
  font-size: 12px;
  color:#666;
  border-radius: 5px;
  margin-bottom:10px;
}
</style>
