<template>
  <el-dialog :title="title" :close-on-click-modal="false" :visible.sync="dialogVisible" width="30%" @close="handleClose">
    <el-card>
      <el-form ref="form" :model="form" :rules="rules" label-width="85px">
        <el-form-item label="归属机构:" prop="companyId">
          <el-select v-model="form.companyId" @change="changeCompany" placeholder="请选择机构">
            <el-option v-for="item in companyList" :key="item.companyId" :label="item.companyName" :value="item.companyId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="员工姓名:" prop="employeeName">
          <el-input v-model.trim="form.employeeName" @input="inputName" placeholder="请输入员工姓名"></el-input>
        </el-form-item>
        <el-form-item label="员工电话:" prop="employeeTel">
          <el-input v-model.trim="form.employeeTel" placeholder="请输入员工电话"></el-input>
        </el-form-item>
        <el-form-item label="岗位:" prop="employeeDuty">
          <el-select v-model="form.employeeDuty" @change="changeGangwei" placeholder="请选择员工岗位">
            <el-option v-for="(item, index) in productList" :key="index" :label="item.dictionaryName" :value="item.dictionaryCode"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="入职时间:">
          <el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd" v-model="form.employeeTime" style="width: 100%;"></el-date-picker>
        </el-form-item>
        <el-form-item label="当前状态:" prop="employeeStuts">
          <el-select v-model="form.employeeStuts" placeholder="请选择用户状态">
            <el-option label="启用" value="1"></el-option>
            <el-option label="禁用" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="简介:" prop="employeeDetail">
          <fuwenben ref="editor"
                    v-model="form.employeeDetail"
                    @onClick="onClick">
          </fuwenben>
<!--          <el-input v-model="form.employeeDetail" @input="inputName" type="textarea" :autosize="{ minRows: 4, maxRows: 10}" placeholder="请输入简介"></el-input>-->
        </el-form-item>
        <el-form-item label="上传图片:" prop="employeePhoto">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload">
            <img v-if="form.employeePhoto" :src="form.employeePhoto" class="avatar" alt="">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
    </el-card>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="submitCommit('form')">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getAllInstitutionsInfo, postRequest } from 'src/api/index'
import { isvalidPhone } from 'src/utils/vaildate'
import {fileuploadaction } from 'src/utils/cities'
import fuwenben from "@/views/infomationManagement/components/fuwenben.vue";
var validPhone=(rule, val,callback)=>{
  let value = val.replace(/[\u200b-\u200f\uFEFF\u202a-\u202e]/g, "")
    if (!value){
      callback(new Error('请输入电话号码'))
    }else  if (!isvalidPhone(value)){
      callback(new Error('请输入正确的11位手机号码'))
    }else {
      callback()
    }
}
export default {
  components: { fuwenben },
  data() {
    return {
      uploadUrl: fileuploadaction,
      form: {
        companyId: '',
        employeeName: '',
        employeeTel: '',
        employeeDuty: '',
        employeePhoto: '',
        employeeTime: '',
        employeeDetail: ''
      },
      rules:{
        employeeTel: [
          {required: true, validator: validPhone, trigger: 'blur'}
        ],
        companyId: [
          {required: true, message: '请选择归属机构', trigger: 'change'}
        ],
        employeeName: [
          {required: true, message: '请输入员工姓名', trigger: 'blur'}
        ],
        employeeDuty: [
          {required: true, message: '请选择岗位', trigger: 'change'}
        ],
        employeeStuts: [
          {required: true, message: '请选择当前员工状态', trigger: 'change'}
        ]
      },
      dialogVisible: true,
      companyList: [],
      title: '新增员工',
      productList: [],
    }
  },
  props: ['info'],
  mounted(){
    let data = {}
    getAllInstitutionsInfo(data).then(res=> {
      console.log(res, '机构列表')
      this.companyList = res
    })
    // 查询 岗位
    postRequest('/dict/getDictByCategory',{dictionaryCategoryCode: '03'}).then(res=>{
      console.log(res, '医生、护士、管家')
      this.productList = res
    })
    console.log(this.info, 'info')
    if (this.info != '') {
      this.form = this.info
      this.title = '修改员工信息'
      this.form.employeeStuts = this.info.employeestuts
    }
  },
  methods: {
    handleAvatarSuccess(res) {
      if (res.code === 200) {
        this.$message({
          message: '图片上传成功',
          type: 'success',
        })
        this.form.employeePhoto = res.data
      }
    },
    beforeAvatarUpload(file) {
      console.log(file)
      const isJPG =['image/png','image/jpeg'].includes(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG或NPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    handleClose() {
      this.$emit('closehandle')
    },
    inputName(){
      this.$forceUpdate()
    },
    onClick (e, editor) {
      console.log('Element clicked')
      console.log(e)
      console.log(editor)
    },
    changeCompany(val){
      this.$forceUpdate()
      console.log(val)
      this.companyList.map(i => {
        if (i.companyId == val) {
          this.form.companyName= i.companyName
          this.form.companyCode= i.companyCode
        }
      })
    },
    changeGangwei(val){
      this.productList.map(item=>{
        if (item.dictionaryCode == val) {
          this.form.employeeDutyName = item.dictionaryName
        }
      })
    },
    submitCommit(form){
      this.form.employeeNickName = this.form.employeeName
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.info == '') {
            postRequest('/Employee/addSave/', this.form).then(res=>{
              console.log(res)
              this.$message({
                message: '添加成功',
                type: 'success'
              })
              this.handleClose()
            })
          }
          if (this.info != '') {
            postRequest('/Employee/update/', this.form).then(res=>{
              console.log(res)
              this.$message({
                message: '修改成功',
                type: 'success'
              })
              this.handleClose()
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  },
}
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}
.el-card {
  margin-top: -20px;
}
.avatar-uploader{
  ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  ::v-deep .el-upload:hover {
    border-color: #409EFF;
  }
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
