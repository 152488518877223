<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="60%"
    :close-on-click-modal="false"
    @close="handleClose">
    <el-card>
      <div class="button">
        <div class="button-a" plain icon="el-icon-download" type="primary"><a :href="downloadsatff">下载模版</a></div>
        <div plain icon="el-icon-upload2" @click="showupLoadFile" type="primary">导入</div>
      </div>
      <el-table
      :data="tableData"
      :header-cell-style="{'text-align': 'center','background': '#EEF1FC', 'color': '#999'}"
      :cell-style="{'text-align':'center'}"
      style="width: 100%">
      <!-- <el-table-column label="导入模版"> -->
        <el-table-column
          prop="employeeName"
          label="姓名">
        </el-table-column>
        <el-table-column
          prop="employeeTel"
          label="电话">
        </el-table-column>
        <el-table-column
          prop="employeeDutyName"
          label="岗位">
        </el-table-column>
        <el-table-column
          prop="companyName"
          label="归属机构">
        </el-table-column>
        <el-table-column
          prop="employeeTime"
          label="入职时间">
        </el-table-column>
        <!-- </el-table-column> -->
      </el-table>
    </el-card>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span>
    <uploadFile :url="url" @closeUpload="closeUpload" v-if="showUpload"></uploadFile>
  </el-dialog>
</template>

<script>
import uploadFile from '@/views/customerManagement/component/uploadFile'
import {filedownaction} from 'src/utils/cities'
export default {
  data () {
    return {
      tableData: [],
      dialogVisible: true,
      showUpload: false,
      url: '',
      downloadsatff: filedownaction + '?type=roster'
    }
  },
  components: { uploadFile },
  methods: {
    handleClose() {
      this.$emit('closestaffRoster')
    },
    showupLoadFile(){
      this.showUpload = true
      this.url = '/Employee/parseEmployeeExcel'
    },
    closeUpload(val){
      this.url = '/Employee/parseEmployeeExcel'
      this.showUpload = false
      console.log(val, '文件提交返回值')
      this.tableData = val
    },
  }
}
</script>

<style lang="scss" scoped>
.button {
  display: flex;
  margin-bottom: 20px;
  > div {
    background: #eef1ff;
    padding: 5px 20px;
    border-radius: 5px;
    margin-right: 10px;
    color: #5967ff;
    border: 1px solid #5967ff;
  }
}
a {
  text-decoration: none;
  color: #5967ff;
}
</style>